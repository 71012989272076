import React from 'react';
import { Helmet } from "react-helmet";
import Link from 'gatsby-link';
import TemplateWrapper from '../../components/layout';

const Stoimost = () => (
  <TemplateWrapper>
  <div className="article-content">
      
          <Helmet>
            <title>Стоимость наращивания волос в Москве</title>

            <meta name="description" content="Сколько стоит нарастить волосы в Москве?" />
            <meta name="keywords" content="цена наращивания волос, стоимость наращивания волос, цениик на наращивание, прайс, прайслист" />
          </Helmet>

          <h1 className="hm-title-1">Стоимость наращивания волос</h1>
          
<p>Цена играет далеко не последнюю роль в выборе техники наращивания волос. Стоимость зависит от многих факторов, которые стоит учесть при выборе того или иного метода. В нее обычно входит оплата донорских прядей. Чем больше их вы используете, тем дороже обойдется наращивание. В среднем мастера применяют 70-100 прядей для создания объемной и красивой прически. В стоимость наращивания входит и работа стилиста. У каждого профессионала свои расценки, но обычно они разнятся всего на 10-20%.</p>
<p>О цене процедуры женщина разговаривает с мастером заранее. Специалист должен встретиться с клиенткой лично, чтобы оценить состояние ее собственных волос и понять, какая длина для наращивания ей подойдет больше всего. Стоимость также зависит и от применяемой техники. Стилист поможет выбрать оптимальный метод, который устроит женщину и по качеству, и по цене. </p>
<p>Обычно в Москве при наращивании используют славянские донорские волосы. Они смотрятся естественно на наших девушках, не создают дополнительные сложности в уходе и имеют продолжительный срок службы. Каждые 2-3 месяца наращенные волосы необходимо перемещать ближе к отросшим корням. Коррекция оплачивается отдельно, проводится в обговоренные сроки или по мере ухудшения внешнего вида наращенных прядей. </p>
<p>О стоимости наращивания вы можете узнать подробнее из <Link to="/prajs" className="hm-link">нашего прайса</Link>. </p>


        </div>
        </TemplateWrapper>
)

export default Stoimost